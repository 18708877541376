import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { redirectToWithUtm } from "../../../components/Header";
const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_Image = require("../../../assets/img/blogs/hr_blog_cover_img.png");
const section_1 = require("../../../assets/img/blogs/sdc_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/sdc_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/sdc_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/sdc_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/sdc_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/sdc_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/sdc_blog_img_7.png");
const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Service Desk Chatbot Automation: A Complete Guide"
        description="AI-powered service desk chatbots drive digital transformation for ITSM. Workativ provides seamless tools for IT leaders to start automating today."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_Image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt sdc_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img text-align-center ${
                  isMobile ? "" : "float-left"
                }`}
              >
                <h1
                  className={`mb-2 ${
                    isMobile
                      ? "font-section-sub-header-blog-title"
                      : "font-section-sub-header-blog-title"
                  }`}
                >
                  A Complete Guide
                </h1>
                <h2
                  className={`${
                    isMobile
                      ? "font-page-header-home-blog"
                      : "font-page-header-home-blog"
                  }`}
                >
                  Service Desk Chatbot <br /> Automation
                </h2>
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is a service desk chatbot?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Why is it overwhelming to handle IT requests without a
                  service desk chatbot?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Use cases of service desk chatbot automation.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Benefits of service desk chatbot automation
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. What should you consider before adopting your service desk
                  chatbot?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. How can Workativ help you?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                  Service desk chatbots
                </a>{" "}
                are an advanced AI-based self-service solution for ITSM teams to
                help expedite ticket resolutions for employee support. However,
                the basic ticketing system limits benefits such as automation,
                workflow management, and self-service capabilities.
                <span className="font-section-normal-text-testimonials-medium">
                  AI-powered service desk assistants or chatbots deliver more
                  than CIOs
                </span>{" "}
                or IT leaders could expect from their digital transformation
                objectives in ITSM.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Read on to learn about service desk chatbots and business
                benefits.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is a service desk chatbot?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk chatbot is a computer program that acts as a
                single point of contact to manage ITSM queries and resolve them
                for ultimate employee support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://assistant.workativ.com/authentication/u/direct-signup">
                  A chatbot for the service desk
                </a>{" "}
                works in a certain way to deliver real-time information and help
                users, unlike the traditional or basic IT ticketing system.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Service desk chatbot improves intent recognition and
                  contextual awareness with conversational AI capabilities
                </li>
                <li className="font-section-normal-text-testimonials">
                  It is fast and straightforward to eliminate confusion as and
                  when conversation volume scales by leveraging NLP and NPU
                </li>
                <li className="font-section-normal-text-testimonials">
                  Users can gain the ability to leverage accurate answers within
                  their familiar business comms tools such as Slack or MS Teams.
                </li>
                <li className="font-section-normal-text-testimonials">
                  No extra effort is required for users to toggle between an
                  ITSM platform and other internal IT tools.
                </li>
                <li className="font-section-normal-text-testimonials">
                  IT help desk chatbot unifies all tools and simplifies ticket
                  handling and resolutions without the users leaving their comms
                  tools.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is it overwhelming to handle IT requests without a service
                desk chatbot?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                In the current scenario, organizations face ticket-handling
                challenges. This is mainly because of a massive shift in work
                culture; however, the existing challenge continues to impact
                user experience.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Current work culture challenges
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                According to{" "}
                <a href="https://www.infotech.com/research/ss/state-of-hybrid-work-in-it?utm_source=pr&utm_medium=referral&utm_campaign=StateofHybridIT">
                  the State of Hybrid Work in IT: A Trend Report,
                </a>{" "}
                hybrid and remote work options significantly strain IT
                departments.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Leaders need to keep pace with delivering hybrid work
                  questions to support changes
                </li>
                <li className="font-section-normal-text-testimonials">
                  Most respondents surveyed agreed that as much as 54% of
                  service desk support is maintained
                </li>
                <li className="font-section-normal-text-testimonials">
                  35% of respondents agreed to meet the needs of hybrid work
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, as per the survey report, the non-traditional models
                seek more attention, tools, and leadership from their
                organizations to perform from anywhere and anytime successfully.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Traditional service desk challenges
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Addressing IT support problems using traditional service desk
                chatbot has many downsides—
              </p>
              <h4 className="font-section-normal-text-medium-customer-banner">
                IT support tracking via emails
              </h4>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  As per a survey by TechValidate, 43% of IT help desks track
                  requests or tickets via email.
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-6 pb-3 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Most tickets land up in inboxes and often lose visibility
                </li>
                <li className="font-section-normal-text-testimonials">
                  Wait time gradually increases for users for manual
                  interventions
                </li>
                <li className="font-section-normal-text-testimonials">
                  Organizations do not possess the necessary communication
                  channels for IT support
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium-customer-banner">
                Requests tracked through phone calls
              </h4>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Ticket handling costs increase when IT support receives phone
                  calls to address tickets.
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-6 pb-3 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  It gets tougher to track and sift through requests with phone
                  calls
                </li>
                <li className="font-section-normal-text-testimonials">
                  Phone calls are time-consuming, causing long queues
                </li>
                <li className="font-section-normal-text-testimonials">
                  The resolution is lengthy for the IT support desk
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium-customer-banner">
                Less visibility into requests
              </h4>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd  mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  According to the global IT research and advisory firm
                  Info-Tech Research,{" "}
                  <a href="https://www.prnewswire.com/news-releases/for-a-more-effective-service-desk-it-should-move-from-traditional-metrics-to-measuring-and-acting-on-customer-feedback-says-info-tech-research-group-301706287.html">
                    IT leaders are not able to harness the essential information
                    required to increase visibility across service desk
                    performance due to their continual reliance on traditional
                    metrics,
                  </a>{" "}
                  causing increased user dissatisfaction.
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-6 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  A lack of visibility restricts improvement capacity for
                  tickets that are repetitive and recurring
                </li>
                <li className="font-section-normal-text-testimonials">
                  Root cause issues remain unresolved, taking up more time to
                  resolve the same issues over and over again
                </li>
                <li className="font-section-normal-text-testimonials">
                  As time increases with a ticket resolution, money also goes
                  wasted in addressing the repetitive issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Issues that can be solved using FAQ-based resources
                  increasingly show up for help desk service
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-square pl-7 mb-1 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials mb-4">
                  The service desk addresses these over and over again
                </li>
                <li className="font-section-normal-text-testimonials">
                  Most common issues involve
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd pl-9 mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Password resets
                </li>
                <li className="font-section-normal-text-testimonials">
                  Account unlock
                </li>
                <li className="font-section-normal-text-testimonials">
                  Restore network issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Handle printer issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Device provisioning
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-square pl-7 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  These issues take up 20-50% of help desk calls to resolve
                </li>
              </ul>
              <BlogCta
                ContentCta="Auto-resolve 60% of Employee Service Desk Queries."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <h4 className="font-section-normal-text-medium-customer-banner">
                High costs to handle tickets
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Ticket handling costs also increase with time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say a company with 3000 employees receives about 5000 tickets
                monthly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For L1 support, per ticket costs $15. So, the monthly cost is
                $45,000. Based on the calculation, the price also increases with
                support escalation.
              </p>
              <h4 className="font-section-normal-text-medium-customer-banner">
                Back-and-forth sign-ins in ITSM tools
              </h4>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Modernized ITSM systems come with sophisticated automation tools
                and a built-in chatbot, so IT help desk communications can be
                seamless. Some limitations, however, impact user experience.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials mb-0 pb-1">
                <li className="font-section-normal-text-testimonials">
                  What about those overwhelming volumes of tickets?
                </li>
                <li className="font-section-normal-text-testimonials">
                  Will it be easy to repeatedly log in and out of the tool to
                  address the issues in real-time?
                </li>
                <li className="font-section-normal-text-testimonials">
                  What if your team member works off-site and needs to log an
                  issue instantly when credentials are inaccessible?
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                A chatbot for the IT service desk that syncs with your business
                comms channels seamlessly works 24x7 to provide help.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Use cases of service desk chatbot automation.
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Business leaders can leverage help desk chatbots to design and
                customize workflows for their business needs. Every organization
                can implement various use cases to manage repetitive and
                time-consuming tasks.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Helpdesk chatbots to help with ticket lifecycle management
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Although a modern ITSM tool involves several manual steps,
                ticket lifecycle management needs active monitoring.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="ticket lifecycle management using service desk chatbot automation "
              />
              <p className="font-section-normal-text-testimonials line-height-18 mb-0  pb-1">
                An IT help desk chatbot allows you to
                <a href="https://workativ.com/conversational-ai-platform/ticket-automation-chatbot">
                  create app workflows and ticket lifecycle management
                  automation
                </a>{" "}
                within your communication channels, such as Slack or Teams.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  If your employee creates a ticket to fix a printer issue, she
                  can do it right within Slack or Teams.
                </li>
                <li className="font-section-normal-text-testimonials">
                  She gets the ticket details instantly in her channel or the IT
                  help desk bot.
                </li>
                <li className="font-section-normal-text-testimonials">
                  An approver gets the details from the same channel.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Requests can be resolved without leaving the channel.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Confirmation of a ‘close ticket’ gets landed in the channel.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                An ITSM leader can easily obtain a complete view of the ticket
                lifecycle from initiation to completion with full details from
                the requester and approver. At the same time, complete
                visibility into the ticket lifecycle helps track issues and
                prepare solutions more flexibly.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Rapid incident management with an IT help desk chatbot
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Auto-resolutions speed
                </span>{" "}
                up ticket deflection rates and help resolve issues at scale. You
                can easily create knowledgebase articles with historical data
                from your service desk and{" "}
                <a href="https://workativ.com/conversational-ai-platform/dropbox-chatbot">
                  enable self-service automation to provide solutions when
                  needed.
                </a>{" "}
                As a result, you allow your employees to handle routine tasks
                such as password resets, printer troubleshooting, device
                provisioning, or simple account unlocking.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s take an example of creating{" "}
                <a href="https://workativ.com/use-cases/unlock-account-automation">
                  app workflow automation for account unlock.
                </a>
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="service desk chatbot automation for knowledgebase articles"
              />

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Create a workflow to design your step-by-step conversations
                </li>
                <li className="font-section-normal-text-testimonials">
                  Use a template to ask for an email address from an employee
                </li>
                <li className="font-section-normal-text-testimonials">
                  Verify employee details in Identity and Access Management
                  (IAM) application
                </li>
                <li className="font-section-normal-text-testimonials">
                  Send and verify OTP via a message service app
                </li>
                <li className="font-section-normal-text-testimonials">
                  Unlock employee accounts in IAM apps such as AuthO, OneLogin,
                  MS Azure AD, etc
                </li>
                <li className="font-section-normal-text-testimonials">
                  Create a ticket in the resolved state for “unlock account
                  request”
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                Instant agent escalation with a service desk chatbot
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                A live agent handover is critical to satisfying your employees
                and transforming their experiences. If a service desk issue is
                beyond the DIY capacity, a live agent handover automation
                seamlessly solves a requester’s problem in real-time without
                repeatedly explaining the history.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="agent handover automation with a service desk chatbot"
              />

              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Workativ Assistant is a conversational AI platform that helps IT
                leaders easily{" "}
                <a href="https://workativ.com/conversational-ai-platform/agent-handover-chatbot">
                  create agent handover automation.
                </a>{" "}
                It just takes a few simple steps to create workflows for an
                agent bot and deploy them to MS Teams or Slack.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="a service desk agent handover automation in MS Teams "
              />

              <p className="font-section-normal-text-testimonials line-height-18">
                With Workativ Assitant, you can use the chatbot builder. Choose
                agent handover and set your workflow automation by creating your
                conversation.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Automate notifications for your service desk chatbot
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                By enabling organizations to create{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  a bot notification mechanism
                </a>
                for the service desk chatbot, IT leaders can take self-healing
                capabilities to the next level. It is usual for organizations to
                take care of various workplace policies, and an automated
                notification helps keep pace with compliance for such policies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                For example,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  if you have an influx of IT tools, keeping an eye on these
                  tools to maintain SLAs is mandatory. An automated notification
                  bot can notify your IT team to keep these tools up to date and
                  upgrade as they approach expiry or end of life.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Not every employee's software expires at the same time. Bot
                  notifications help keep every employee informed of system
                  requirements and updated about when to reset system passwords.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Bot notifications enable organizations to carry out surveys to
                  collect feedback about workplace culture, which further helps
                  build workplace harmony and employee empathy.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Also, it is easy to celebrate special moments like work
                  anniversaries or employees' birthdays. This is a better way to
                  improve employee engagement and retention, as these warm
                  gestures give employees a sense of value.
                </li>
              </ul>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Service desk notification bot for automated communications"
              />
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Using the virtual assistant platform from Wokativ, you can set
                up notifications using app triggers that can nudge bots to take
                action for a specific event in the apps.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Benefits of service desk chatbot automation
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Leveraging a service desk chatbot delivers many benefits.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Employees love auto-resolutions
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                A service desk chatbot seamlessly sits with your business
                communication channels, such as Slack or Teams, removing the
                friction between user conversations and the IT help desk.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                This helps improve auto-resolution through app workflow
                automation and thus enhances employee experience.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Time-saving for critical issues
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Auto-resolutions through self-service capabilities quickly solve
                80% of repetitive IT support tasks. So, business leaders can
                save time and dedicate their efforts to solving more critical
                and complex IT issues.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Cost-efficiency
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                With a traditional service desk, it takes organizations $45,000
                to solve L1 support issues monthly (this is in reference to an
                organization with 3000 employees). However, with a service desk
                chatbot automation, this monthly cost comes down to $21,000 per
                month.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                In addition, chatbot automation for the IT help desk drives down
                the cost of ticket handling. No matter the business size, it
                reduces costs{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  from $25 to less than $2
                </span>{" "}
                for L1 support.
              </p>
              <h3 className="font-section-sub-header-small-home">
                24x7 employee support availability
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                When you start embracing hybrid and remote work culture,
                self-service-level solutions with a service desk chatbot provide
                24x7 accessibility to information and help. This is effective
                when you need to increase your employees' adaptability to change
                management. Your IT support can handle a backlog of overwhelming
                IT requests.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Integrations with multiple ITSM tools
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                A service desk chatbot automation effortlessly works with any
                ITSM tools and their infrastructure. If you want to embrace
                digital transformation, you no longer need to spend money
                separately to buy a new license. An IT helpdesk chatbot from{" "}
                <a href="https://workativ.com/conversational-ai-platform/freshdesk-chatbot">
                  Workativ Assistant
                </a>{" "}
                allows seamless integration with as many ITSM tools as required
                and creates your app workflows and automation.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What should you consider before adopting your service desk
                chatbot?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Service desk automation is necessary rather than a nice-to-have
                drive. So, consider some essential facts when addressing the
                growing IT support challenges with chatbot automations.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Know your goals:
                </span>{" "}
                Your automation project might have multiple business objectives.
                Decide what you want to accomplish 一 user experience, cost
                efficiency, and other business results.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Audit IT support history to create your workflows:
                </span>{" "}
                Connect with your IT support team and analyze the IT requests
                your team receives daily. Prioritize workflows and create app
                workflow automation for repetitive processes.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Start small with the most relevant IT issues:
                </span>{" "}
                Create your workflow automation for the most pertinent issues.
                Track the performance, customize, and improve the process. If
                you are successful in your attempt, launch workflow automation
                for other IT processes, too.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How can Workativ help you?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Workativ offers a{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai-bot">
                  conversational AI chatbot platform
                </a>{" "}
                to help you build your personalized service desk chatbot. With
                Workativ, leveraging all the capabilities to build a service
                desk chatbot and integrating it with your communication channels
                like Slack or Microsoft Teams is easier.
              </p>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>
                  Create your chatbot without writing a single line of code{" "}
                </li>
                <li>
                  Just play around with your chatbot with a drag-and-drop
                  interface{" "}
                </li>
                <li>
                  Reduce call volumes by 40% in the first year with your IT
                  support{" "}
                </li>
                <li>
                  Get significant labor reduction with Workativ service desk
                  chatbot by 20-30% in 1st year
                </li>
                <li>
                  Reduce the need to opt for an enterprise package for
                  ServiceNow to avail chatbot facility
                </li>
                <li>Achieve 5X lower MTTR for repeated service desk issues</li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                To learn more about how Workativ can unleash immense benefits
                for your IT support team through its service desk chatbot
                automation,{" "}
                <a href="https://assistant.workativ.com/authentication/u/direct-signup">
                  schedule a demo today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. Can you drive maximum benefits with a traditional chatbot?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Traditional chatbots limit automation and seem rudimentary. They
                are mainly rule-based or keyword-based. When a unique question
                arises, the conventional chatbot forwards the same repetitive
                answers, offering no real help or employee support.
              </p>

              <h3 className="font-section-sub-header-small">
                2. Does a traditional chatbot meet service desk objectives?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desks receive differing employee requests, which can
                turn overwhelming for traditional service desk chatbots. As a
                result, simple employee requests pile up in the queue for
                agents, eventually increasing the resolution time.
              </p>

              <h3 className="font-section-sub-header-small">
                3. What is a service desk chatbot automation?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk chatbot with AI/ML capabilities works as a single
                point of contact to automate mundane workflows and help resolve
                employee service requests for ITSM.
              </p>

              <h3 className="font-section-sub-header-small">
                4. How does a service desk chatbot work?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                An AI-powered chatbot provides exceptional automation
                capabilities that help improve intent and context understanding
                using NLP and NLU. Implementing self-service and automating
                employee support requests for service desks is the best
                approach.
              </p>
              <h3 className="font-section-sub-header-small">
                5. What routine employee support tasks does a service desk
                chatbot enable?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk chatbot uses automation to streamline routine
                tasks such as password resets, account unlocks, software
                installs, user provisions, etc.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is a service desk chatbot?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Why is it overwhelming to handle IT requests without a
                    service desk chatbot?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Use cases of service desk chatbot automation.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Benefits of service desk chatbot automation
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. What should you consider before adopting your service
                    desk chatbot?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. How can Workativ help you?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                  Service desk chatbots
                </a>{" "}
                are an advanced AI-based self-service solution for ITSM teams to
                help expedite ticket resolutions for employee support. However,
                the basic ticketing system limits benefits such as automation,
                workflow management, and self-service capabilities.
                <span className="font-section-normal-text-testimonials-medium">
                  AI-powered service desk assistants or chatbots deliver more
                  than CIOs
                </span>{" "}
                or IT leaders could expect from their digital transformation
                objectives in ITSM.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Read on to learn about service desk chatbots and business
                benefits.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is a service desk chatbot?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk chatbot is a computer program that acts as a
                single point of contact to manage ITSM queries and resolve them
                for ultimate employee support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://assistant.workativ.com/authentication/u/direct-signup">
                  A chatbot for the service desk
                </a>{" "}
                works in a certain way to deliver real-time information and help
                users, unlike the traditional or basic IT ticketing system.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Service desk chatbot improves intent recognition and
                  contextual awareness with conversational AI capabilities
                </li>
                <li className="font-section-normal-text-testimonials">
                  It is fast and straightforward to eliminate confusion as and
                  when conversation volume scales by leveraging NLP and NPU
                </li>
                <li className="font-section-normal-text-testimonials">
                  Users can gain the ability to leverage accurate answers within
                  their familiar business comms tools such as Slack or MS Teams.
                </li>
                <li className="font-section-normal-text-testimonials">
                  No extra effort is required for users to toggle between an
                  ITSM platform and other internal IT tools.
                </li>
                <li className="font-section-normal-text-testimonials">
                  IT help desk chatbot unifies all tools and simplifies ticket
                  handling and resolutions without the users leaving their comms
                  tools.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is it overwhelming to handle IT requests without a service
                desk chatbot?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                In the current scenario, organizations face ticket-handling
                challenges. This is mainly because of a massive shift in work
                culture; however, the existing challenge continues to impact
                user experience.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Current work culture challenges
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                According to{" "}
                <a href="https://www.infotech.com/research/ss/state-of-hybrid-work-in-it?utm_source=pr&utm_medium=referral&utm_campaign=StateofHybridIT">
                  the State of Hybrid Work in IT: A Trend Report,
                </a>{" "}
                hybrid and remote work options significantly strain IT
                departments.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Leaders need to keep pace with delivering hybrid work
                  questions to support changes
                </li>
                <li className="font-section-normal-text-testimonials">
                  Most respondents surveyed agreed that as much as 54% of
                  service desk support is maintained
                </li>
                <li className="font-section-normal-text-testimonials">
                  35% of respondents agreed to meet the needs of hybrid work
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, as per the survey report, the non-traditional models
                seek more attention, tools, and leadership from their
                organizations to perform from anywhere and anytime successfully.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Traditional service desk challenges
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Addressing IT support problems using traditional service desk
                chatbot has many downsides—
              </p>
              <h4 className="font-section-normal-text-medium-customer-banner">
                IT support tracking via emails
              </h4>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  As per a survey by TechValidate, 43% of IT help desks track
                  requests or tickets via email.
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-6 pb-3 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Most tickets land up in inboxes and often lose visibility
                </li>
                <li className="font-section-normal-text-testimonials">
                  Wait time gradually increases for users for manual
                  interventions
                </li>
                <li className="font-section-normal-text-testimonials">
                  Organizations do not possess the necessary communication
                  channels for IT support
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium-customer-banner">
                Requests tracked through phone calls
              </h4>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Ticket handling costs increase when IT support receives phone
                  calls to address tickets.
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-6 pb-3 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  It gets tougher to track and sift through requests with phone
                  calls
                </li>
                <li className="font-section-normal-text-testimonials">
                  Phone calls are time-consuming, causing long queues
                </li>
                <li className="font-section-normal-text-testimonials">
                  The resolution is lengthy for the IT support desk
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium-customer-banner">
                Less visibility into requests
              </h4>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd  mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  According to the global IT research and advisory firm
                  Info-Tech Research,{" "}
                  <a href="https://www.prnewswire.com/news-releases/for-a-more-effective-service-desk-it-should-move-from-traditional-metrics-to-measuring-and-acting-on-customer-feedback-says-info-tech-research-group-301706287.html">
                    IT leaders are not able to harness the essential information
                    required to increase visibility across service desk
                    performance due to their continual reliance on traditional
                    metrics,
                  </a>{" "}
                  causing increased user dissatisfaction.
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-circle pl-6 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  A lack of visibility restricts improvement capacity for
                  tickets that are repetitive and recurring
                </li>
                <li className="font-section-normal-text-testimonials">
                  Root cause issues remain unresolved, taking up more time to
                  resolve the same issues over and over again
                </li>
                <li className="font-section-normal-text-testimonials">
                  As time increases with a ticket resolution, money also goes
                  wasted in addressing the repetitive issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Issues that can be solved using FAQ-based resources
                  increasingly show up for help desk service
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-square pl-7 mb-1 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials mb-4">
                  The service desk addresses these over and over again
                </li>
                <li className="font-section-normal-text-testimonials">
                  Most common issues involve
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd pl-9 mb-2 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Password resets
                </li>
                <li className="font-section-normal-text-testimonials">
                  Account unlock
                </li>
                <li className="font-section-normal-text-testimonials">
                  Restore network issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Handle printer issues
                </li>
                <li className="font-section-normal-text-testimonials">
                  Device provisioning
                </li>
              </ul>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-square pl-7 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  These issues take up 20-50% of help desk calls to resolve
                </li>
              </ul>
              <BlogCta
                ContentCta="Auto-resolve 60% of Employee Service Desk Queries."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <h4 className="font-section-normal-text-medium-customer-banner">
                High costs to handle tickets
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Ticket handling costs also increase with time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say a company with 3000 employees receives about 5000 tickets
                monthly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For L1 support, per ticket costs $15. So, the monthly cost is
                $45,000. Based on the calculation, the price also increases with
                support escalation.
              </p>
              <h4 className="font-section-normal-text-medium-customer-banner">
                Back-and-forth sign-ins in ITSM tools
              </h4>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Modernized ITSM systems come with sophisticated automation tools
                and a built-in chatbot, so IT help desk communications can be
                seamless. Some limitations, however, impact user experience.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials mb-0 pb-1">
                <li className="font-section-normal-text-testimonials">
                  What about those overwhelming volumes of tickets?
                </li>
                <li className="font-section-normal-text-testimonials">
                  Will it be easy to repeatedly log in and out of the tool to
                  address the issues in real-time?
                </li>
                <li className="font-section-normal-text-testimonials">
                  What if your team member works off-site and needs to log an
                  issue instantly when credentials are inaccessible?
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                A chatbot for the IT service desk that syncs with your business
                comms channels seamlessly works 24x7 to provide help.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Use cases of service desk chatbot automation.
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Business leaders can leverage help desk chatbots to design and
                customize workflows for their business needs. Every organization
                can implement various use cases to manage repetitive and
                time-consuming tasks.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Helpdesk chatbots to help with ticket lifecycle management
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Although a modern ITSM tool involves several manual steps,
                ticket lifecycle management needs active monitoring.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="ticket lifecycle management using service desk chatbot automation "
              />
              <p className="font-section-normal-text-testimonials line-height-18 mb-0  pb-1">
                An IT help desk chatbot allows you to
                <a href="https://workativ.com/conversational-ai-platform/ticket-automation-chatbot">
                  create app workflows and ticket lifecycle management
                  automation
                </a>{" "}
                within your communication channels, such as Slack or Teams.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  If your employee creates a ticket to fix a printer issue, she
                  can do it right within Slack or Teams.
                </li>
                <li className="font-section-normal-text-testimonials">
                  She gets the ticket details instantly in her channel or the IT
                  help desk bot.
                </li>
                <li className="font-section-normal-text-testimonials">
                  An approver gets the details from the same channel.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Requests can be resolved without leaving the channel.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Confirmation of a ‘close ticket’ gets landed in the channel.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                An ITSM leader can easily obtain a complete view of the ticket
                lifecycle from initiation to completion with full details from
                the requester and approver. At the same time, complete
                visibility into the ticket lifecycle helps track issues and
                prepare solutions more flexibly.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Rapid incident management with an IT help desk chatbot
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Auto-resolutions speed
                </span>{" "}
                up ticket deflection rates and help resolve issues at scale. You
                can easily create knowledgebase articles with historical data
                from your service desk and{" "}
                <a href="https://workativ.com/conversational-ai-platform/dropbox-chatbot">
                  enable self-service automation to provide solutions when
                  needed.
                </a>{" "}
                As a result, you allow your employees to handle routine tasks
                such as password resets, printer troubleshooting, device
                provisioning, or simple account unlocking.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s take an example of creating{" "}
                <a href="https://workativ.com/use-cases/unlock-account-automation">
                  app workflow automation for account unlock.
                </a>
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="service desk chatbot automation for knowledgebase articles"
              />

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Create a workflow to design your step-by-step conversations
                </li>
                <li className="font-section-normal-text-testimonials">
                  Use a template to ask for an email address from an employee
                </li>
                <li className="font-section-normal-text-testimonials">
                  Verify employee details in Identity and Access Management
                  (IAM) application
                </li>
                <li className="font-section-normal-text-testimonials">
                  Send and verify OTP via a message service app
                </li>
                <li className="font-section-normal-text-testimonials">
                  Unlock employee accounts in IAM apps such as AuthO, OneLogin,
                  MS Azure AD, etc
                </li>
                <li className="font-section-normal-text-testimonials">
                  Create a ticket in the resolved state for “unlock account
                  request”
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                Instant agent escalation with a service desk chatbot
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                A live agent handover is critical to satisfying your employees
                and transforming their experiences. If a service desk issue is
                beyond the DIY capacity, a live agent handover automation
                seamlessly solves a requester’s problem in real-time without
                repeatedly explaining the history.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="agent handover automation with a service desk chatbot"
              />

              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Workativ Assistant is a conversational AI platform that helps IT
                leaders easily{" "}
                <a href="https://workativ.com/conversational-ai-platform/agent-handover-chatbot">
                  create agent handover automation.
                </a>{" "}
                It just takes a few simple steps to create workflows for an
                agent bot and deploy them to MS Teams or Slack.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="a service desk agent handover automation in MS Teams "
              />

              <p className="font-section-normal-text-testimonials line-height-18">
                With Workativ Assitant, you can use the chatbot builder. Choose
                agent handover and set your workflow automation by creating your
                conversation.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Automate notifications for your service desk chatbot
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                By enabling organizations to create{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  a bot notification mechanism
                </a>
                for the service desk chatbot, IT leaders can take self-healing
                capabilities to the next level. It is usual for organizations to
                take care of various workplace policies, and an automated
                notification helps keep pace with compliance for such policies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                For example,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  if you have an influx of IT tools, keeping an eye on these
                  tools to maintain SLAs is mandatory. An automated notification
                  bot can notify your IT team to keep these tools up to date and
                  upgrade as they approach expiry or end of life.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Not every employee's software expires at the same time. Bot
                  notifications help keep every employee informed of system
                  requirements and updated about when to reset system passwords.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Bot notifications enable organizations to carry out surveys to
                  collect feedback about workplace culture, which further helps
                  build workplace harmony and employee empathy.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Also, it is easy to celebrate special moments like work
                  anniversaries or employees' birthdays. This is a better way to
                  improve employee engagement and retention, as these warm
                  gestures give employees a sense of value.
                </li>
              </ul>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Service desk notification bot for automated communications"
              />
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Using the virtual assistant platform from Wokativ, you can set
                up notifications using app triggers that can nudge bots to take
                action for a specific event in the apps.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Benefits of service desk chatbot automation
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Leveraging a service desk chatbot delivers many benefits.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Employees love auto-resolutions
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                A service desk chatbot seamlessly sits with your business
                communication channels, such as Slack or Teams, removing the
                friction between user conversations and the IT help desk.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                This helps improve auto-resolution through app workflow
                automation and thus enhances employee experience.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Time-saving for critical issues
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Auto-resolutions through self-service capabilities quickly solve
                80% of repetitive IT support tasks. So, business leaders can
                save time and dedicate their efforts to solving more critical
                and complex IT issues.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Cost-efficiency
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                With a traditional service desk, it takes organizations $45,000
                to solve L1 support issues monthly (this is in reference to an
                organization with 3000 employees). However, with a service desk
                chatbot automation, this monthly cost comes down to $21,000 per
                month.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                In addition, chatbot automation for the IT help desk drives down
                the cost of ticket handling. No matter the business size, it
                reduces costs{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  from $25 to less than $2
                </span>{" "}
                for L1 support.
              </p>
              <h3 className="font-section-sub-header-small-home">
                24x7 employee support availability
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                When you start embracing hybrid and remote work culture,
                self-service-level solutions with a service desk chatbot provide
                24x7 accessibility to information and help. This is effective
                when you need to increase your employees' adaptability to change
                management. Your IT support can handle a backlog of overwhelming
                IT requests.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Integrations with multiple ITSM tools
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                A service desk chatbot automation effortlessly works with any
                ITSM tools and their infrastructure. If you want to embrace
                digital transformation, you no longer need to spend money
                separately to buy a new license. An IT helpdesk chatbot from{" "}
                <a href="https://workativ.com/conversational-ai-platform/freshdesk-chatbot">
                  Workativ Assistant
                </a>{" "}
                allows seamless integration with as many ITSM tools as required
                and creates your app workflows and automation.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What should you consider before adopting your service desk
                chatbot?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Service desk automation is necessary rather than a nice-to-have
                drive. So, consider some essential facts when addressing the
                growing IT support challenges with chatbot automations.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Know your goals:
                </span>{" "}
                Your automation project might have multiple business objectives.
                Decide what you want to accomplish 一 user experience, cost
                efficiency, and other business results.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Audit IT support history to create your workflows:
                </span>{" "}
                Connect with your IT support team and analyze the IT requests
                your team receives daily. Prioritize workflows and create app
                workflow automation for repetitive processes.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Start small with the most relevant IT issues:
                </span>{" "}
                Create your workflow automation for the most pertinent issues.
                Track the performance, customize, and improve the process. If
                you are successful in your attempt, launch workflow automation
                for other IT processes, too.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How can Workativ help you?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Workativ offers a{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai-bot">
                  conversational AI chatbot platform
                </a>{" "}
                to help you build your personalized service desk chatbot. With
                Workativ, leveraging all the capabilities to build a service
                desk chatbot and integrating it with your communication channels
                like Slack or Microsoft Teams is easier.
              </p>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>
                  Create your chatbot without writing a single line of code{" "}
                </li>
                <li>
                  Just play around with your chatbot with a drag-and-drop
                  interface{" "}
                </li>
                <li>
                  Reduce call volumes by 40% in the first year with your IT
                  support{" "}
                </li>
                <li>
                  Get significant labor reduction with Workativ service desk
                  chatbot by 20-30% in 1st year
                </li>
                <li>
                  Reduce the need to opt for an enterprise package for
                  ServiceNow to avail chatbot facility
                </li>
                <li>Achieve 5X lower MTTR for repeated service desk issues</li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                To learn more about how Workativ can unleash immense benefits
                for your IT support team through its service desk chatbot
                automation,{" "}
                <a href="https://assistant.workativ.com/authentication/u/direct-signup">
                  schedule a demo today.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. Can you drive maximum benefits with a traditional chatbot?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Traditional chatbots limit automation and seem rudimentary. They
                are mainly rule-based or keyword-based. When a unique question
                arises, the conventional chatbot forwards the same repetitive
                answers, offering no real help or employee support.
              </p>

              <h3 className="font-section-sub-header-small">
                2. Does a traditional chatbot meet service desk objectives?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desks receive differing employee requests, which can
                turn overwhelming for traditional service desk chatbots. As a
                result, simple employee requests pile up in the queue for
                agents, eventually increasing the resolution time.
              </p>

              <h3 className="font-section-sub-header-small">
                3. What is a service desk chatbot automation?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk chatbot with AI/ML capabilities works as a single
                point of contact to automate mundane workflows and help resolve
                employee service requests for ITSM.
              </p>

              <h3 className="font-section-sub-header-small">
                4. How does a service desk chatbot work?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                An AI-powered chatbot provides exceptional automation
                capabilities that help improve intent and context understanding
                using NLP and NLU. Implementing self-service and automating
                employee support requests for service desks is the best
                approach.
              </p>
              <h3 className="font-section-sub-header-small">
                5. What routine employee support tasks does a service desk
                chatbot enable?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk chatbot uses automation to streamline routine
                tasks such as password resets, account unlocks, software
                installs, user provisions, etc.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>

      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              ITSM CHATBOT: Use Cases and Benefits Explained
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/enterprise-service-desk-guide"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Ultimate Guide (2023) Enterprise Service Desk
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              How can Enterprises Leverage Generative AI?
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
